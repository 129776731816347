@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.signup-container {
  width: calc(100vw - 20px); }
  .signup-container .left-col {
    padding: 0; }
    .signup-container .left-col .login-left {
      background-color: white;
      height: 100vh;
      padding: 20% 15% 10% 15%; }
      .signup-container .left-col .login-left Form {
        padding-top: 10%; }
      .signup-container .left-col .login-left button {
        width: 100%;
        margin-top: 15px; }
        .signup-container .left-col .login-left button .icon {
          padding-right: 4px; }
      .signup-container .left-col .login-left .link-to-login {
        padding: 10% 15% 10% 20%; }
        .signup-container .left-col .login-left .link-to-login a {
          color: #00a1d6; }
  .signup-container .right-col {
    padding: 0px; }
    .signup-container .right-col .login-right {
      background-color: yellow;
      width: 100%;
      height: 100%; }
      .signup-container .right-col .login-right img {
        width: 100%;
        height: 100vh;
        object-fit: cover; }
      .signup-container .right-col .login-right h2 {
        position: absolute;
        top: 90%;
        left: 40%;
        color: white; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.home-container .nav-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 80px;
  color: #919ca7;
  line-height: 30px; }
  .home-container .nav-section .logo {
    color: #fb7299;
    text-align: left;
    font-weight: 700;
    font-size: 22px; }
  .home-container .nav-section .navs {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600; }
  .home-container .nav-section .login {
    text-align: center;
    width: 100%; }
    .home-container .nav-section .login a {
      background-color: #EEF2F7;
      width: 100%;
      color: #474D56;
      border: 1px solid transparent;
      border-radius: 20px;
      padding: 5px 20px; }
      .home-container .nav-section .login a span {
        margin-right: 4px; }
      .home-container .nav-section .login a:hover {
        cursor: pointer; }

.home-container .hero-section {
  padding: 80px 0 120px 0;
  color: #919ca7;
  position: relative;
  height: 600px; }
  .home-container .hero-section .slogan {
    margin-top: 15%;
    font-size: 30px;
    font-weight: 600;
    color: white; }
  .home-container .hero-section .changing {
    font-size: 35px;
    font-weight: 700;
    color: white; }
  .home-container .hero-section .sub-slogan {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px; }
  .home-container .hero-section button {
    margin-top: 40px;
    padding: 10px 20px; }
    .home-container .hero-section button a {
      color: white;
      font-weight: 500; }
      .home-container .hero-section button a span {
        margin-left: 5px;
        font-size: 12px; }
  .home-container .hero-section::after {
    content: "";
    background-image: -webkit-gradient(linear, left top, left bottom, from(#565d71), to(#2b303f));
    background-image: -webkit-linear-gradient(top, #565d71, #2b303f);
    background-image: linear-gradient(to bottom, #565d71, #2b303f);
    position: absolute;
    top: -200px;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    -webkit-transform: skewY(-3deg);
            transform: skewY(-3deg); }
  .home-container .hero-section .img-wrapper {
    width: 80%; }

.home-container .how-it-works-section {
  margin-top: 150px; }
  .home-container .how-it-works-section .title {
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px; }
  .home-container .how-it-works-section .nav-item {
    margin: 5px 20px; }
    .home-container .how-it-works-section .nav-item a {
      padding: 30px 40px;
      color: rgba(0, 0, 0, 0.7);
      border-radius: 15px;
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      font-weight: 700; }
      .home-container .how-it-works-section .nav-item a span {
        margin-right: 20px;
        font-size: 20px; }
      .home-container .how-it-works-section .nav-item a.active {
        color: #00a1d6;
        background-color: rgba(0, 161, 214, 0.11); }
  .home-container .how-it-works-section .img-wrapper {
    height: 500px; }
    .home-container .how-it-works-section .img-wrapper img {
      height: 100%; }

.home-container .call-to-action-section {
  border-radius: 200px 100% 0 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 161, 214, 0.5)), to(rgba(0, 161, 214, 0.9)));
  background-image: -webkit-linear-gradient(top, rgba(0, 161, 214, 0.5), rgba(0, 161, 214, 0.9));
  background-image: linear-gradient(to bottom, rgba(0, 161, 214, 0.5), rgba(0, 161, 214, 0.9));
  padding: 10%;
  text-align: center;
  color: white;
  font-family: 'Nunito', sans-serif;
  font-size: 25px;
  font-weight: 700; }
  .home-container .call-to-action-section a {
    margin-left: 20px;
    background-color: white;
    padding: 20px 25px;
    border-radius: 20px;
    font-size: 20px;
    color: rgba(0, 161, 214, 0.8); }
    .home-container .call-to-action-section a:hover, .home-container .call-to-action-section a:active {
      color: #00a1d6; }

.home-container .footer {
  text-align: center;
  padding: 50px;
  color: #8c98a4;
  font-size: 14px; }
  .home-container .footer .logo {
    color: #fb7299;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 10px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.navbar-container {
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin-bottom: 50px; }
  .navbar-container .navbar-brand {
    color: black;
    font-size: 22px;
    font-weight: 800; }
  .navbar-container .username {
    color: #6c757d;
    font-weight: 600;
    margin-right: 10px; }
  .navbar-container .dropdown .dropdown-wrapper {
    cursor: pointer; }
  .navbar-container .dropdown .dropdown-content {
    right: 0;
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5px; }
  .navbar-container .dropdown .dropdown-content a {
    color: #5c5759;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px; }
  .navbar-container .dropdown .dropdown-content a:hover {
    background-color: #f8f9fa; }
  .navbar-container .dropdown .dropdown-content {
    display: block; }
  .navbar-container .dropdown img {
    width: 40px;
    height: 40px;
    border-radius: 40px; }
  .navbar-container .dropdown:hover {
    display: block; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.navleft-container {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 30px 20px; }
  .navleft-container .navleft-user {
    text-align: center; }
    .navleft-container .navleft-user img {
      width: 80px;
      height: 80px;
      border-radius: 40px; }
    .navleft-container .navleft-user p {
      margin-top: 10px;
      color: #323a46;
      font-weight: 700;
      font-size: .9rem; }
  .navleft-container ul.navs {
    list-style-type: none;
    padding: 5px 0px;
    color: #6c757d;
    font-family: 'Nunito', sans-serif;
    font-weight: 600; }
    .navleft-container ul.navs li {
      margin: 18px 0px; }
      .navleft-container ul.navs li span {
        display: inline-block;
        width: 50px;
        text-align: center; }
      .navleft-container ul.navs li a {
        cursor: pointer;
        color: inherit;
        text-decoration: none; }
      .navleft-container ul.navs li a.active {
        color: #fb7299; }
    .navleft-container ul.navs li:hover {
      color: #fb7299; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.overview-container {
  background-color: rgba(247, 249, 252, 0.9); }
  .overview-container .overview-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
    .overview-container .overview-header a {
      background-color: #fb7299;
      color: white;
      font-family: 'Nunito', sans-serif;
      font-size: 18px;
      border-radius: 20px;
      padding: 10px 20px;
      cursor: pointer;
      text-decoration: none; }
  .overview-container .panel {
    background-color: white;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    padding: 20px;
    margin-top: 20px; }
    .overview-container .panel .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
      .overview-container .panel .panel-header span {
        background-color: rgba(157, 209, 164, 0.25);
        color: #42d29d;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #eef2f7;
        border-radius: .25rem;
        text-align: center;
        padding: 7px; }
      .overview-container .panel .panel-header span.blue {
        background-color: rgba(155, 195, 250, 0.25);
        color: #00a1d6; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.login-container {
  width: calc(100vw - 20px); }
  .login-container .left-col {
    padding: 0 !important; }
    .login-container .left-col .login-left {
      position: relative;
      background-color: white;
      height: 100vh;
      padding: 40% 15% 10% 15%; }
      .login-container .left-col .login-left Form {
        padding-top: 10%; }
      .login-container .left-col .login-left button {
        width: 100%;
        margin-top: 15px; }
        .login-container .left-col .login-left button .icon {
          margin-right: 5px; }
      .login-container .left-col .login-left .link-to-signup {
        padding: 10% 15% 10% 20%; }
        .login-container .left-col .login-left .link-to-signup a {
          color: #00a1d6; }
  .login-container .right-col {
    padding: 0 !important; }
    .login-container .right-col .login-right img {
      width: 100%;
      height: 100vh;
      object-fit: cover; }
    .login-container .right-col .login-right h2 {
      position: absolute;
      top: 90%;
      left: 40%;
      color: white; }

.room-container .roomRight {
  height: calc(100vh - 130px);
  padding: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15); }
  .room-container .roomRight .roomInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid rgba(218, 218, 220, 0.1); }
    .room-container .roomRight .roomInfo h2 {
      font-size: 26px;
      color: black;
      margin-bottom: 5px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.commit-container button {
  margin-top: 15px;
  border: none;
  font-size: 14.4px;
  background-color: #f1f1f4;
  width: 44px;
  height: 44px;
  border-radius: 44px;
  color: #2b2c28;
  cursor: pointer; }

.commit-container button:hover {
  background-color: rgba(100, 100, 100, 0.7); }

.commit-container #leave {
  margin-right: 10px;
  padding-left: 15px; }

.commit-container #clear {
  margin-right: 10px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.chatroom-container {
  border-radius: .25rem;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding-bottom: 10px; }
  .chatroom-container .message-list {
    padding: 20px;
    height: calc(100vh - 320px);
    overflow-y: scroll; }
  .chatroom-container .chatroom-input {
    clear: both;
    height: 40px;
    padding: 20px;
    display: block; }
    .chatroom-container .chatroom-input .text {
      width: 70%; }
    .chatroom-container .chatroom-input .form-group {
      display: inline-block;
      width: calc(100% - 60px); }
      .chatroom-container .chatroom-input .form-group input {
        background-color: #f1f1f4;
        border: none;
        border-radius: 24px;
        padding: 26px 20px; }
        .chatroom-container .chatroom-input .form-group input:focus {
          border-color: inherit;
          box-shadow: none; }
    .chatroom-container .chatroom-input button {
      background-color: #fb7299;
      border: none;
      display: inline-block;
      float: right;
      height: 52px;
      width: 52px;
      border-radius: 52px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.message-container {
  clear: both; }
  .message-container img {
    margin-top: 10px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    float: left; }
  .message-container .message-sender {
    margin-top: 10px;
    float: left;
    margin-left: 10px;
    width: calc(100% - 50px);
    font-size: 12px;
    color: #b7b9bb; }
  .message-container .message-content {
    max-width: 450px;
    float: left;
    margin-left: 10px;
    display: inline-block;
    margin-top: 4px;
    background-color: #f2f6f9;
    padding: 14px 12px;
    border-radius: 0px 10px 10px 10px; }
    .message-container .message-content p {
      line-height: 22px;
      margin-bottom: 0; }

.message-container.isOwner img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  float: right; }

.message-container.isOwner .message-sender {
  display: none; }

.message-container.isOwner .message-content {
  display: inline-block;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #dcf4fc;
  padding: 14px 12px;
  border-radius: 10px 0px 10px 10px; }
  .message-container.isOwner .message-content p {
    line-height: 22px;
    margin-bottom: 0; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.pomodoro-container button {
  border: none;
  font-size: 14.4px; }
  .pomodoro-container button span {
    margin-right: 5px; }

.pomodoro-container #leave {
  background-color: #FF5B5B;
  margin-right: 10px; }

.pomodoro-container #commit {
  background-color: #42d29d; }

.pomodoro-container #count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #6c757d;
  font-weight: 600;
  margin-top: 100px;
  margin-bottom: 400px; }

.pomodoro-container .CircularProgressbar {
  width: 200px;
  height: 200px; }

.pomodoro-container .control {
  color: #fb7299;
  font-size: 30px;
  position: relative;
  top: -75px;
  left: 90px;
  cursor: pointer; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.pomo-modal .form-label {
  font-size: 16px;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  margin-top: 10px; }

.pomo-modal .hidden-btn {
  color: #fb7299;
  font-size: 13px;
  border: 1px solid #fb7299;
  border-radius: 20px;
  padding: 4px 10px;
  cursor: pointer; }

.pomo-modal .hidden-btn.cancel {
  float: right;
  color: rgba(22, 22, 22, 0.3);
  border-color: rgba(22, 22, 22, 0.2); }

.pomo-modal .invalid-feedback {
  display: block; }

.pomo-modal .valid-feedback {
  display: block; }

.pomo-modal .add-project-wrapper {
  margin-top: 10px; }
  .pomo-modal .add-project-wrapper input {
    margin-left: 10px;
    background-color: #f1f1f4;
    border: none !important;
    font-size: 14px;
    outline: none;
    border-radius: 15px;
    padding: 5px 60px 5px 20px; }
    .pomo-modal .add-project-wrapper input:focus {
      border-color: inherit;
      box-shadow: none; }
    .pomo-modal .add-project-wrapper input:active {
      border-color: inherit;
      box-shadow: none; }

.pomo-modal .add-project-wrapper.create {
  margin-top: 13px; }

.pomo-modal .add-tag-wrapper {
  margin-top: 10px; }
  .pomo-modal .add-tag-wrapper input {
    margin-left: 10px;
    background-color: #f1f1f4;
    border: none !important;
    font-size: 14px;
    outline: none;
    border-radius: 15px;
    padding: 5px 60px 5px 20px; }
    .pomo-modal .add-tag-wrapper input:focus {
      border-color: inherit;
      box-shadow: none; }
    .pomo-modal .add-tag-wrapper input:active {
      border-color: inherit;
      box-shadow: none; }

.pomo-modal .add-tag-wrapper.create {
  margin-top: 13px; }


body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.usersinroom-container .single-user {
  margin-right: 5px;
  display: inline-block; }
  .usersinroom-container .single-user img {
    width: 26px;
    height: 26px;
    border-radius: 26px; }

.loading-modal .modal-content {
  width: 200px;
  height: 200px;
  margin-left: calc(50% - 100px);
  margin-top: 200px; }
  .loading-modal .modal-content .modal-body {
    padding: 50px;
    display: flex;
    justify-content: center; }
    .loading-modal .modal-content .modal-body span {
      display: block; }



body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.pomodoro-container button {
  border: none;
  font-size: 14.4px; }
  .pomodoro-container button span {
    margin-right: 5px; }

.pomodoro-container #leave {
  background-color: #FF5B5B;
  margin-right: 10px; }

.pomodoro-container #commit {
  background-color: #42d29d; }

.pomodoro-container #count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: #6c757d;
  font-weight: 600;
  margin-top: 100px;
  margin-bottom: 400px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.pomo-modal .form-label {
  font-size: 16px;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  margin-top: 10px; }

.pomo-modal .hidden-btn {
  color: #fb7299;
  font-size: 13px;
  border: 1px solid #fb7299;
  border-radius: 20px;
  padding: 4px 10px;
  cursor: pointer; }

.pomo-modal .hidden-btn.cancel {
  float: right;
  color: rgba(22, 22, 22, 0.3);
  border-color: rgba(22, 22, 22, 0.2); }

.pomo-modal .invalid-feedback {
  display: block; }

.pomo-modal .valid-feedback {
  display: block; }

.pomo-modal .add-project-wrapper {
  margin-top: 10px; }
  .pomo-modal .add-project-wrapper input {
    margin-left: 10px;
    background-color: #f1f1f4;
    border: none !important;
    font-size: 14px;
    outline: none;
    border-radius: 15px;
    padding: 5px 60px 5px 20px; }
    .pomo-modal .add-project-wrapper input:focus {
      border-color: inherit;
      box-shadow: none; }
    .pomo-modal .add-project-wrapper input:active {
      border-color: inherit;
      box-shadow: none; }

.pomo-modal .add-project-wrapper.create {
  margin-top: 13px; }

.pomo-modal .add-tag-wrapper {
  margin-top: 10px; }
  .pomo-modal .add-tag-wrapper input {
    margin-left: 10px;
    background-color: #f1f1f4;
    border: none !important;
    font-size: 14px;
    outline: none;
    border-radius: 15px;
    padding: 5px 60px 5px 20px; }
    .pomo-modal .add-tag-wrapper input:focus {
      border-color: inherit;
      box-shadow: none; }
    .pomo-modal .add-tag-wrapper input:active {
      border-color: inherit;
      box-shadow: none; }

.pomo-modal .add-tag-wrapper.create {
  margin-top: 13px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.project-container .project-header {
  margin-top: 10px;
  margin-bottom: 30px; }

.project-container .create-button {
  background-color: #fb7299;
  border: none; }

.project-container .project-table {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding: 20px 20px 2px 20px; }
  .project-container .project-table Table {
    border: hidden;
    color: #6c757d; }
    .project-container .project-table Table th, .project-container .project-table Table td {
      text-align: left;
      padding: 8px; }
    .project-container .project-table Table tr:hover {
      background-color: #FDEBE7; }
    .project-container .project-table Table .delete-button {
      background-color: rgba(242, 133, 137, 0.15);
      color: #fb7299;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid #eef2f7;
      border-radius: .25rem;
      text-align: center;
      padding: 7px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.tag-container .project-header {
  margin-top: 10px;
  margin-bottom: 30px; }

.tag-container .create-button {
  background-color: #fb7299;
  border: none; }

.tag-container .project-table {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding: 20px 20px 2px 20px; }
  .tag-container .project-table Table {
    border: hidden;
    color: #6c757d; }
    .tag-container .project-table Table th, .tag-container .project-table Table td {
      text-align: left;
      padding: 8px; }
    .tag-container .project-table Table tr:hover {
      background-color: #FDEBE7; }
    .tag-container .project-table Table .delete-button {
      background-color: rgba(242, 133, 137, 0.15);
      color: #fb7299;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 1px solid #eef2f7;
      border-radius: .25rem;
      text-align: center;
      padding: 7px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.inroom-container {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 15px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15); }
  .inroom-container img {
    width: 74%;
    z-index: 1001;
    margin: 25px auto; }
  .inroom-container a {
    margin: 0 auto;
    width: 150px;
    display: block;
    color: inherit;
    text-decoration: none;
    color: white;
    background: #fb7299;
    border: none;
    border-radius: 40px;
    padding: 10px 40px; }
    .inroom-container a:hover {
      background: #fb7299; }
    .inroom-container a:active {
      background-color: rgba(240, 122, 126, 0.5) !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.joinroom-container {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 15px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15); }
  .joinroom-container img {
    width: 78%;
    z-index: 1001;
    margin: 25px auto; }
  .joinroom-container .joinroom-form {
    color: #5c5759;
    background-color: #eef2f7;
    padding: 12px;
    font-family: "Nunito", sans-serif;
    border-radius: .25rem; }
    .joinroom-container .joinroom-form input {
      background-color: white;
      font-family: "Nunito", sans-serif;
      padding: 5px 15px;
      border-radius: 5px;
      outline: none;
      border: none;
      font-size: 14px;
      width: 80%; }
    .joinroom-container .joinroom-form span {
      margin-left: 10px;
      background-color: rgba(157, 209, 164, 0.25);
      color: #42d29d;
      border-radius: 3px;
      padding: 4px 7px;
      cursor: pointer; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.createroom-container {
  width: 100%;
  text-align: center;
  background-color: white;
  padding: 15px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15); }
  .createroom-container img {
    width: 75%;
    z-index: 1001;
    margin: 25px auto; }
  .createroom-container .createroom-form {
    color: #5c5759;
    background-color: #eef2f7;
    padding: 12px;
    font-family: "Nunito", sans-serif;
    border-radius: .25rem; }
    .createroom-container .createroom-form input {
      background-color: white;
      font-family: "Nunito", sans-serif;
      padding: 5px 15px;
      border-radius: 5px;
      outline: none;
      border: none;
      font-size: 14px;
      width: 80%; }
    .createroom-container .createroom-form span {
      margin-left: 10px;
      background-color: rgba(155, 195, 250, 0.25);
      color: #00a1d6;
      border-radius: 3px;
      padding: 4px 7px;
      cursor: pointer; }

.door-container .door-header {
  margin-top: 10px;
  margin-bottom: 30px; }

.door-container .owns-room-table {
  background-color: white;
  margin-top: 50px;
  padding: 20px 20px 2px 20px;
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }
  .door-container .owns-room-table th {
    border: none;
    font-size: 18px;
    font-weight: 700;
    color: #6c757d;
    font-family: 'Nunito', sans-serif; }
  .door-container .owns-room-table td {
    color: #6c757d; }
    .door-container .owns-room-table td button {
      background-color: #98a6ad;
      border: none; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.account-container .account-body {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 30px 30px; }
  .account-container .account-body .account-avatar img {
    width: 100px;
    height: 100px;
    border-radius: 5px; }
  .account-container .account-body .account-avatar span {
    margin-left: 30px;
    color: #42d29d;
    border: 1px #42d29d solid;
    padding: 6px 14px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif; }
  .account-container .account-body form {
    margin-top: 30px; }
    .account-container .account-body form .form-group {
      margin-top: 20px; }
      .account-container .account-body form .form-group .form-label {
        font-size: 16px;
        font-weight: 700;
        font-family: 'Nunito', sans-serif;
        color: #6c757d; }
    .account-container .account-body form button {
      margin-top: 20px;
      float: right;
      background-color: #42d29d;
      border: none;
      border-radius: 20px;
      padding: 6px 20px; }

body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.password-container .password-body {
  background-color: white;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  padding: 30px 30px; }
  .password-container .password-body .account-avatar img {
    width: 100px;
    height: 100px;
    border-radius: 5px; }
  .password-container .password-body .account-avatar span {
    margin-left: 30px;
    color: #42d29d;
    border: 1px #42d29d solid;
    padding: 6px 14px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Nunito', sans-serif; }
  .password-container .password-body form {
    margin-top: 30px; }
    .password-container .password-body form .form-group {
      margin-top: 20px; }
      .password-container .password-body form .form-group .form-label {
        font-size: 16px;
        font-weight: 700;
        font-family: 'Nunito', sans-serif;
        color: #6c757d; }
    .password-container .password-body form button {
      margin-top: 20px;
      float: right;
      background-color: #42d29d;
      border: none;
      border-radius: 20px;
      padding: 6px 20px; }

.match-container .not-ready-container .waiting-container {
  margin: 200px auto;
  width: 600px;
  text-align: center; }
  .match-container .not-ready-container .waiting-container img {
    width: 200px; }
  .match-container .not-ready-container .waiting-container p {
    margin-top: 20px;
    color: #6c757d;
    font-size: 18px; }
  .match-container .not-ready-container .waiting-container button {
    margin-top: 40px;
    padding: 12px 60px;
    border-radius: 40px; }

.match-container .not-ready-container .matching-container {
  margin: 200px auto;
  width: 400px;
  text-align: center; }
  .match-container .not-ready-container .matching-container img {
    width: 200px; }
  .match-container .not-ready-container .matching-container p {
    margin-top: 20px;
    color: #6c757d;
    font-size: 18px; }
  .match-container .not-ready-container .matching-container button {
    margin-top: 40px;
    padding: 12px 60px;
    border-radius: 40px; }

.match-container .joining-container {
  margin: 200px auto;
  width: 400px;
  text-align: center; }
  .match-container .joining-container img {
    width: 200px; }
  .match-container .joining-container p {
    margin-top: 20px;
    color: #6c757d;
    font-size: 18px; }
  .match-container .joining-container button {
    margin-top: 40px;
    padding: 12px 60px;
    border-radius: 40px; }


body,
html,
div#root,
#root,
h1 {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0; }

h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif; }

h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600; }

body {
  background-color: rgba(247, 249, 252, 0.9);
  font-family: 'Nunito', sans-serif; }

button:hover {
  box-shadow: 0px 0px 7px 1px #bbb; }

.btn-primary {
  background-color: #fb7299;
  border-color: #fb7299; }
  .btn-primary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 7px 1px #bbb; }
  .btn-primary:active, .btn-primary:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-secondary {
  border: none;
  background-color: #f1f1f4;
  color: #2b2c28; }
  .btn-secondary:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-secondary:active, .btn-secondary:hover {
    border: none !important;
    outline: none !important;
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, 0.2); }

.btn-info {
  background-color: rgba(0, 161, 214, 0.8);
  border: none; }
  .btn-info:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-info:active, .btn-info:hover {
    background-color: #00a1d6 !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.btn-success {
  background-color: #42d29d;
  border: none; }
  .btn-success:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
  .btn-success:active, .btn-success:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }

.modal-content {
  border: none;
  border-radius: 18px; }
  .modal-content button {
    background-color: #fb7299;
    border: none;
    padding: 10px 30px;
    border-radius: 20px; }
  .modal-content .modal-header {
    background-color: #fb7299;
    border: none;
    border-radius: 18px 18px 0 0;
    padding: 25px 40px; }
    .modal-content .modal-header .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif; }
  .modal-content .modal-body {
    border: none;
    padding: 25px 40px; }
    .modal-content .modal-body form {
      margin: 30px 0px 25px 0px; }
      .modal-content .modal-body form input {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px; }
        .modal-content .modal-body form input::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-moz-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input:-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::-ms-input-placeholder {
          color: rgba(0, 0, 0, 0.4); }
        .modal-content .modal-body form input::placeholder {
          color: rgba(0, 0, 0, 0.4); }
  .modal-content .modal-footer {
    padding: 20px 40px; }
    .modal-content .modal-footer #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px; }

.alert p {
  margin: 0; }

.alert button:hover {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.alert button:focus {
  border-color: inherit;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important; }

.notfound-mainbody {
  align-content: center;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 60%; }
  .notfound-mainbody .left-content {
    margin: auto; }
  .notfound-mainbody .notfound-img {
    width: 300px; }

