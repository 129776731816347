@import "../../index.scss";

.notfound-mainbody {
  align-content: center;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  .left-content {
    margin: auto;
  }
  .btn-primary {
  }
  .notfound-img {
    width: 300px;
  }
}
