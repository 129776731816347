@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&display=swap');

// $primary-color: rgba(254,112,95,.75);
// $primary-green: rgb(0,196,112);
// $primary-blue: rgb(73,111,234);
// $primary-yellow: rgb(245, 210, 140);
// $primary-pink: rgb(250, 160, 177);
//
// $bg-color-primary: rgba(242, 133, 137,.15);
// $bg-color-light: rgba(247,249,252,.9);
// $bg-color-green: rgba(16,196,105,.25);
// $bg-color-`: rgba(83,109,230,.25);
// $bg-color-yellow: #fef5e4;
// $bg-color-blue-primary: rgba(83,109,230,.6);
// $bg-color-grey:rgb(245, 245, 245);
//
// $primary-font: 'Nunito', sans-serif;
// $primary-font-color: #6c757d;

// $primary-color: rgb(240, 122, 126);
$primary-color: rgb(251, 114, 153);
// $primary-green: rgb(157, 209, 164);
$primary-green: rgb(66,210,157);
// $primary-blue: rgb(155, 195, 250);
$primary-blue: rgb(0,161,214);
$primary-yellow: rgb(245, 210, 140);
$primary-pink: rgb(250, 160, 177);

$bg-color-light: rgba(247,249,252,.9);
$bg-color-green: rgba(157, 209, 164,.25);
$bg-color-blue: rgba(155, 195, 250,.25);
$bg-color-yellow: rgba(244, 216, 161,.25);
$bg-color-primary: rgba(242, 133, 137,.15);
$bg-color-grey:rgb(245, 245, 245);

$primary-font: 'Nunito', sans-serif;
$primary-font-color: rgb(92, 87, 89);

$primary-button-blue:rgb(64, 133, 230);
$primary-button-red:rgb(240, 122, 126);
$primary-button-green:rgb(54, 125, 93);

body,
html,
div#root,
#root,
h1
{
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
}
h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
}
h3 {
  font-size: 19px;
  font-weight: 700;
  color: #6c757d;
  font-family: 'Nunito', sans-serif;
}
h5 {
  font-size: 15px;
  color: #98a6ad;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

body {
  background-color: $bg-color-light;
  font-family: 'Nunito', sans-serif;
}

button:hover {
  -webkit-box-shadow: 0px 0px 7px 1px #bbb;
  -moz-box-shadow: 0px 0px 7px 1px #bbb;
  box-shadow: 0px 0px 7px 1px #bbb;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  &:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    -webkit-box-shadow: 0px 0px 7px 1px #bbb;
    -moz-box-shadow: 0px 0px 7px 1px #bbb;
    box-shadow: 0px 0px 7px 1px #bbb;
  }
  &:active, &:hover {
    background-color: rgba(251, 114, 153, 0.9) !important;
    border-color: inherit;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, .2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, .2);
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, .2)
  }
}

.btn-secondary {
  border: none;
  background-color: rgb(241,241,244);
  color: rgb(43,44,40);
  &:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  &:active, &:hover {
    // background-color: rgba(241,241,244,.5) !important;
    border: none !important;
    outline: none !important;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, .2);
    -moz-box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, .2);
    box-shadow: 0px 0px 10px 2px rgba(120, 120, 120, .2);
  }
}

.btn-info {
  // background-color: $primary-blue;
  background-color: rgba(0,161,214, .8);
  border: none;
  &:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  &:active, &:hover {
    background-color: rgb(0,161,214) !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.btn-success {
  background-color: $primary-green;
  border: none;
  &:focus {
    border-color: inherit;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  &:active, &:hover {
    background-color: #2ec08a !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

//Modal
.modal-content{
  border: none;
  border-radius: 18px;
  button {
    background-color:  $primary-color;
    border: none;
    padding: 10px 30px;
    border-radius: 20px;
  }
  .modal-header {
    background-color: $primary-color;
    border: none;
    border-radius: 18px 18px 0 0 ;
    padding: 25px 40px;
    .modal-title {
      font-size: 19px;
      font-weight: 700;
      color: white;
      font-family: 'Nunito', sans-serif;
    }
  }
  .modal-body {
    border: none;
    padding: 25px 40px;
    form {
      margin: 30px 0px 25px 0px;
      input {
        border: 1px solid rgba(0,0,0,0.1); 
        padding: 20px;
        &::placeholder {
          color:rgba(0,0,0,0.4);
        }
      }
    }
  }
  .modal-footer {
    padding: 20px 40px;
    #close {
      color: #474d56;
      background-color: #d4deeb;
      border-color: #cbd7e7;
      margin-right: 15px;
    }
  }
}

// Alert
.alert {
  p {
    margin: 0;
  }
  button {
    &:hover {
      border-color: inherit;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
    &:focus {
      border-color: inherit;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
}
